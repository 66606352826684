.container.skill__container {
  text-align: center;
  height: 100vh;
}


/* .react-multi-carousel-list {
  margin-top: 2rem;
}

.custom-arrow {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .custom-prev-arrow {
    left: 10px;
  }
  
  .custom-next-arrow {
    right: 10px;
  }
  
  .CircularProgressbarWithChildren {
    margin: 1rem;
  } */

  .p {
    justify-content: left;
  }

  h3 {
    color: var(--color-primary);
  }