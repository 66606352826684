header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ====== CTA ====== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ====== HEADER SOCIALS ====== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    border: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ================ VERTICALCAROUSEL ================ */
.VerticalCarousel {
    margin-top: 1rem;
    font-size: 500;
    color: gold;
    margin-bottom: 1rem;
}

/* =========== header__avatarcarousel =================*/
.header__avatarcarousel {
    height: 55%;
    width: 50%;
}

.swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}


/* Style the pagination bullets */
.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background-color: #aaa;
    opacity: 0.5;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}
  
.swiper-pagination-bullet-active {
    opacity: 1;
}

.header__avatarcarousel img {
    border-radius: 1.5rem;
    overflow: hidden;
}

/* ======       SCROLL DOWN        ====== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1024px) {
    header {
        height: 60vh;
    }
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}